.filters-container {
    width: 50vw;
    margin: auto;
    display: flex;
    color: white;
}

.filter-category-container {
    display: flex;
    flex-direction: column;
    margin-right: 3vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
}

.filter-category-title {
    font-size: 1vw;
    font-weight: 500;
}

.filter-option-category {
    display: flex;
    align-items: center;
}

.filter-option {
    font-size: .75vw;
}

.filter-checkbox-container {
    position: relative;
    margin-right: .5vw;
    width: .6vw;
    height: .6vw;
    border-radius: .05vw;
    cursor: pointer;
    &:hover {
        border: .05vw solid white;
        width: .5vw;
        height: .5vw;
    }
    &:hover .filter-checkbox-icon {
        align-items: baseline;
        margin-top: 0vw;
        margin-left: 0vw;
    }
}

.filter-checkbox-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: .5vw;
    margin-left: .08vw;
    margin-top: .05vw;
}

.sort-circle-container {
    position: relative;
}

.sort-circle-icon-outer {
    font-size: .7vw;
    position: absolute;
    top: -.3vw;
    left: 0px;
    cursor: pointer;
}

.sort-circle-icon-inner {
    font-size: .5vw;
    position: absolute;
    top: -.19vw;
    left: .09vw;
    cursor: pointer;
}