.search-results-container-mobile {
    color: white;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.card-result-container-mobile {
    background-color: #101010;
    width: 90vw;
    margin-bottom: 1.5vw;
    border-bottom: 2px solid #2A2A2A;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card-title-mobile {
    font-size: 4vw;
    text-align: center;
    margin-bottom: 1vh;
    margin-top: 1vh;
    text-decoration: none;
    color: white;
    cursor: pointer;
    &:hover {
        color: #00C405;
    }
}

.card-image-mobile-a {
    margin-top: 1vh;
}

.card-image-mobile {
    max-height: 30vh;
    max-width: 85vw;
    border-radius: 1vw;
    cursor: pointer;
    &:hover {
        box-shadow: 0 0 .5vw #00C405;
    }
}

.card-info-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.card-price-and-seller-mobile {
    display: flex;
    height: 100%;
}

.card-price-mobile {
    font-size: 5vw;
    color: #00C405;
}

.card-seller-mobile {
    font-size: 5vw;
    margin-top: auto;
    margin-bottom: auto;
}

.show-more-button-mobile {
    margin-left: .75vw;
    margin-right: .75vw;
    border: .075vw solid #00C405;
    font-size: 5vw;
    border-radius: 100vw;
    padding: .5em 1em;
    background-color: #0E0E0E;
    color: white;
    cursor: pointer;
    transition: transform 0.3s ease;
    &:hover {
        background-color: #00C405;
        transform: scale(1.1, 1.1);
    }
    margin-bottom: 5vh;
    margin-top: 4vh;
}

.results-loading-spinner-mobile {
    font-size: 12.5vw;
    margin-top: 5vh;
    color: #00C405;
}

.card-rating-mobile {
    display: flex;
    font-size: 5vw;
    margin: auto;
}

.rating-text-mobile {
    margin-left: 1vw;
    line-height: 5vw;
}