.search-container-mobile {
    margin-top: 1vh;
    display: flex;
    align-items: center;
}

.search-bar-mobile {
    margin: auto;
    width: 90vw;
    margin-bottom: 1.5vh;
    font-size: 7.25vw;
    border: .2vw solid #00C405;
    background-color: #2A2A2A;
    color: white;
    &:focus { 
        outline: none !important;
        box-shadow: 0 0 .75vw #00C405;
    }
}