.search-container {
    margin-top: 5vh;
    display: flex;
    align-items: center;
}

.search-bar {
    margin: auto;
    font-size: 4vw;
    border: .2vw solid #00C405;
    background-color: #2A2A2A;
    color: white;
    &:focus { 
        outline: none !important;
        box-shadow: 0 0 .75vw #00C405;
    }
}