.search-results-container {
    color: white;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.card-result-container {
    background-color: #2A2A2A;
    width: 47vw;
    padding: 1.5vw;
    margin-bottom: 1.5vw;
    border-radius: .5vw;
    display: flex;
}

.card-title {
    font-size: .85vw;
    cursor: pointer;
    color: white;
    text-decoration: none;
    &:hover {
        color: #00C405;
    }
}

.card-image {
    max-height: 8vw !important;
    cursor: pointer;
    border-radius: .5vw;
    &:hover {
        box-shadow: 0 0 .5vw #00C405;
    }
}

.card-image-a {
    margin-right: 5vw;
}

.card-info {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.card-price-and-seller {
    display: flex;
    height: 100%;
}

.card-price {
    font-size: 1.25vw;
    color: #00C405;
    margin: auto;
    margin-bottom: 0px;
    margin-left: 0px;
}

.card-seller {
    font-size: .85vw;
    margin: auto;
    margin-bottom: 0px;
    margin-right: 0px;
}

.show-more-button {
    margin-left: .75vw;
    margin-right: .75vw;
    border: .075vw solid #00C405;
    font-size: 1.1vw;
    border-radius: 100vw;
    padding: .5em 1em;
    background-color: #0E0E0E;
    color: white;
    cursor: pointer;
    transition: transform 0.3s ease;
    &:hover {
        background-color: #00C405;
        transform: scale(1.1, 1.1);
    }
    margin-bottom: 5vh;
}

.results-loading-spinner {
    font-size: 2.5vw;
    margin-top: 5vh;
    color: #00C405;
}

.card-rating {
    margin-top: .25vw;
    display: flex;
    font-size: .85vw;
}

.rating-text {
    margin-left: .25vw;
    line-height: 1.1vw;
}