.filters-container-mobile {

}

.filters-header-container-mobile {
    display: flex;
    color: white;
    width: 90vw;
    margin: auto;
    margin-bottom: 2vh;
    padding-bottom: 1vh;
    border-bottom: 2px solid #2A2A2A;
}

.results-header-mobile {
    margin: auto;
    margin-left: 0px;
    font-size: 5vw;
}

.filter-button-container-mobile {
    margin: auto;
    margin-right: 0px;
    font-size: 5vw;
}

.filters-popup-container-mobile {
    animation: filters-popup-container-load-mobile .35s;
    z-index: 2;
    position: absolute;
    bottom: -100px !important;
    width: calc(100vw - 4vw);
    height: calc(50vh - 8vw + 100px);
    padding: 4vw;
    padding-right: 0px;
    background-color: #2A2A2A;
    border-top-left-radius: 4vw;
    border-top-right-radius: 4vw;
    color: white;
}

@keyframes filters-popup-container-load-mobile {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }
}

.filter-popup-header-mobile {
    display: flex;
    font-size: 4.5vw;
    margin-bottom: 2vh;
}

.filter-popup-header-title {
    margin: auto;
    margin-left: 0px;
}

.filter-popup-close-button-mobile {
    margin: auto;
    margin-right: 4vw;
}

.filter-categories-container-mobile {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 40.5vh;
}

.filter-category-container-mobile {
    display: flex;
    flex-direction: column;
    margin-right: 3vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
}

.filter-category-title-mobile {
    font-size: 4.5vw;
    margin-bottom: .5vh;
}

.filter-options-category-mobile {
    display: flex;
    flex-wrap: wrap;
}

.filter-option-category-mobile {
    font-size: 4vw;
    background-color: #101010;
    padding: 1vw;
    padding-right: 1.5vw;
    padding-left: 1.5vw;
    border-radius: 1vw;
    margin-right: 2vw;
    margin-bottom: 2vw;
}

.filter-backdrop-mobile {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 1;
    top: 0px;
}